import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/1.Security/1.Security/apps/client/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/(landing)/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-roboto\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/1.Security/1.Security/apps/client/src/auth/context/next-auth/auth-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/1.Security/1.Security/apps/client/src/data-access/react-query/react-query-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/src/theme/globalStyles.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/src/theme/reset.global.css");
